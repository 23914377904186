<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">ข้อมูลลูกค้าใหม่</h4>
      </div>

      <div class="d-flex flex-row align-end justify-start mb-1">
        <span>ข้อมูลทั่วไปของลูกค้า</span>
        <v-dialog
          v-model="modalMoreHistory"
          persistent
          max-width="840px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              class="ml-5 white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              กรอกประวัติเพิ่มเติม
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">ประวัติเพิ่มเติม</span>
              <v-spacer/>
              <span class="body-1">{{`วันที่ ${inputCustomer.registerDate}`}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="inputCustomer.fname"
                      label="ชื่อ"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="inputCustomer.lname"
                      label="นามสกุล"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="moreHistory.nickname"
                      :rules="inputRules"
                      label="ชื่อเล่น*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="inputCustomer.phonenumber"
                      label="เบอร์โทรศัพท์"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-select
                      :items="itemsStatus"
                      v-model="moreHistory.status"
                      item-text="name"
                      item-value="value"
                      label="สถานภาพ*"
                      no-data-text="ยังไม่มีข้อมูลสถานภาพ"
                      :rules="selectRules"
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-select
                      :items="itemsGender"
                      v-model="moreHistory.gender"
                      item-text="name"
                      item-value="value"
                      label="เพศ*"
                      no-data-text="ยังไม่มีข้อมูลเพศ"
                      :rules="selectRules"
                      required
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="moreHistory.nationality"
                      :rules="inputRules"
                      label="สัญชาติ*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="computedBirthdateFormatted"
                      label="วัน/เดือน/ปีเกิด"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="inputCustomer.yearold"
                      label="อายุ"
                      suffix="ปี"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="3"
                  >
                    <v-text-field
                      v-model="moreHistory.email"
                      :rules="inputRules"
                      label="E-mail*"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider/>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-3 mb-0 pb-0"
                  >
                  <span>ปัจจุบันท่านประกอบอาชีพใด</span>
                  <v-radio-group
                    v-model="moreHistory.occupation"
                    row
                  >
                    <v-radio
                      label="รับราชการ"
                      value="occupation-1"
                    ></v-radio>
                    <v-radio
                      label="พนักงานรัฐวิสาหกิจ"
                      value="occupation-2"
                    ></v-radio>
                    <v-radio
                      label="เจ้าของกิจการ"
                      value="occupation-3"
                    ></v-radio>
                    <v-radio value="occupation-4">
                      <template v-slot:label>
                        <v-text-field
                          v-model="moreHistory.occupationetc"
                          label="อื่น ๆ"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>ปัจจุบันท่านมีรายได้เท่าใดต่อเดือน</span>
                  <v-radio-group
                    v-model="moreHistory.income"
                    row
                  >
                    <v-radio
                      label="น้อยกว่า 15,000"
                      value="income-1"
                    ></v-radio>
                    <v-radio
                      label="15,000 - 30,000"
                      value="income-2"
                    ></v-radio>
                    <v-radio
                      label="30,001 - 50,000"
                      value="income-3"
                    ></v-radio>
                    <v-radio
                      label="50,001 - 100,000"
                      value="income-4"
                    ></v-radio>
                    <v-radio
                      label="มากกว่า 100,000"
                      value="income-5"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>ท่ายเคยใช้บริการคลินิกความงามหรือคลินิกลดน้ำหนักมาก่อนหรือไม่</span>
                  <v-radio-group
                    v-model="moreHistory.havebeengotoclinic"
                    row
                  >
                    <v-radio
                      label="ไม่เคย"
                      value="havebeengotoclinic-1"
                    ></v-radio>
                    <v-radio value="havebeengotoclinic-2">
                      <template v-slot:label>
                        <v-text-field
                          v-model="moreHistory.havebeengotoclinicetc"
                          label="เคย ระบุหัตถการที่เคยทำ"
                          hide-details
                        ></v-text-field>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>ปัจจุบันท่านออกกำลังกายสัปดาห์ละกี่ครั้ง</span>
                  <v-radio-group
                    v-model="moreHistory.exercise"
                    row
                  >
                    <v-radio
                      label="ทุกวัน"
                      value="exercise-1"
                    ></v-radio>
                    <v-radio
                      label="4 - 6 ครั้ง"
                      value="exercise-2"
                    ></v-radio>
                    <v-radio
                      label="1 - 3 ครั้ง"
                      value="exercise-3"
                    ></v-radio>
                    <v-radio
                      label="น้อยกว่านั้น"
                      value="exercise-4"
                    ></v-radio>
                    <v-radio
                      label="ไม่ได้ออกกำลังกายเลย"
                      value="exercise-5"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <v-combobox
                    v-model="moreHistory.needtoprove"
                    :items="itemsNeedProve"
                    :search-input.sync="searchNeedProve"
                    hide-selected
                    label="ปัญหาที่ท่านต้องการแก้ไข (เลือกได้มากกว่า 1 ปัญหา)"
                    multiple
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            ไม่พบข้อมูล "<strong>{{ searchNeedProve }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>งบกระมาณที่ท่านตั้งไว้ในการแก้ไขปัญหา</span>
                  <v-radio-group
                    v-model="moreHistory.needtoprovecost"
                    row
                  >
                    <v-radio
                      label="น้อยกว่า 3,000"
                      value="needtoprovecost-1"
                    ></v-radio>
                    <v-radio
                      label="3,000 - 5,000"
                      value="needtoprovecost-2"
                    ></v-radio>
                    <v-radio
                      label="5,001 - 10,000"
                      value="needtoprovecost-3"
                    ></v-radio>
                    <v-radio
                      label="10,0001 - 20,000"
                      value="needtoprovecost-4"
                    ></v-radio>
                    <v-radio
                      label="มากกว่า 20,000"
                      value="needtoprovecost-5"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span class="d-flex flex-row align-baseline">
                    จำนวนครั้งที่ท่านเข้ามารับบริการและคาดหวังเห็นผลในการแก้ไขปัญหา
                    (<v-menu
                      ref="menuFinishLookdate"
                      v-model="menuFinishLookdate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="mx-2"
                          :value="computedFinishLookdateFormatted"
                          label="ท่านมีแพลนใช้หน้าวันที่เท่าใดหรือไม่"
                          placeholder="ท่านมีแพลนใช้หน้าวันที่เท่าใดหรือไม่"
                          readonly
                          hide-details
                          @click:append-outer="menuFinishLookdate = true"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="moreHistory.finishlookdate"
                        :min="moment().format('YYYY')"
                        no-title
                        @change="menuFinishLookdate = false"
                      ></v-date-picker>
                    </v-menu>)
                  </span>
                  <v-radio-group
                    v-model="moreHistory.timehavebeen"
                    row
                  >
                    <v-radio
                      label="1 ครั้ง"
                      value="timehavebeen-1"
                    ></v-radio>
                    <v-radio
                      label="2 - 3 ครั้้ง"
                      value="timehavebeen-2"
                    ></v-radio>
                    <v-radio
                      label="4 - 5 ครั้ง"
                      value="timehavebeen-3"
                    ></v-radio>
                    <v-radio
                      label="6 - 10 ครั้ง"
                      value="timehavebeen-4"
                    ></v-radio>
                    <v-radio
                      label="มากกว่า 10 ครั้ง"
                      value="timehavebeen-5"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>ความถี่ที่ท่านสามารถเข้ามาใช้บริการที่คลินิกได้</span>
                  <v-radio-group
                    v-model="moreHistory.canwalkin"
                    row
                  >
                    <v-radio
                      label="ทุกวัน"
                      value="canwalkin-1"
                    ></v-radio>
                    <v-radio
                      label="4 - 6 วัน/สัปดาห์"
                      value="canwalkin-2"
                    ></v-radio>
                    <v-radio
                      label="1 - 3 วัน/สัปดาห์"
                      value="canwalkin-3"
                    ></v-radio>
                    <v-radio
                      label="น้อยกว่านั้น"
                      value="canwalkin-4"
                    ></v-radio>
                  </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <v-combobox
                    v-model="moreHistory.objecttoselectclinice"
                    :items="itemsSelectClinic"
                    :search-input.sync="searchSelectClinic"
                    hide-selected
                    label="ปัจจัยใดที่มีผลต่อการเลือกใช้บริการคลินิกความงามของท่านมากที่สุด"
                    small-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            ไม่พบข้อมูล "<strong>{{ searchSelectClinic }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    class="mt-0 pt-0"
                  >
                  <span>ปัจจุบันท่านใช้บัตรเครดิตหรือไม่</span>
                  <v-radio-group
                    v-model="moreHistory.havecreditcard"
                    row
                  >
                    <v-radio
                      label="ไม่มี"
                      value="nocreditcard"
                    ></v-radio>
                    <v-radio value="havecreditcard">
                      <template v-slot:label>
                        <div class="d-flex flex-row align-end">
                          <v-text-field
                            v-model="moreHistory.numberofcreditcard"
                            placeholder="จำนวน"
                            prefix="มี"
                            suffix="ใบ"
                            single-line
                            hide-details
                          ></v-text-field>
                          <v-combobox
                            v-if="moreHistory.havecreditcard === 'havecreditcard'"
                            class="ml-2"
                            v-model="moreHistory.selectcreditcard"
                            :items="itemsCreditCard"
                            :search-input.sync="searchCreditCard"
                            hide-selected
                            single-line
                            hide-details
                            label="บัตรเครดิตที่มีอยู่"
                            small-chips
                            multiple
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    ไม่พบข้อมูล "<strong>{{ searchCreditCard }}</strong>" กดปุ่ม <kbd>Enter</kbd> เพื่อเพิ่ม
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
              <small>*ต้องระบุ</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="cancelDialogMoreHistory"
              >
                ยกเลิก
              </v-btn>
              <v-btn
                color="success"
                text
                @click="confirmDialogMoreHistory"
              >
                ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-row class="pt-2">
        <v-col cols="12" sm="12" md="12" lg="8">
          <div class="border-inform-customer pa-3">
            <v-form v-model="formValid">
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.fname"
                    :rules="inputRules"
                    label="ชื่อ*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.lname"
                    :rules="inputRules"
                    label="นามสกุล*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-menu
                    ref="menuBirthdate"
                    v-model="menuBirthdate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedBirthdateFormatted"
                        label="วัน/เดือน/ปีเกิด*"
                        placeholder="ระบุวัน/เดือน/ปีเกิด"
                        append-outer-icon="mdi-calendar-month"
                        readonly
                        required
                        @click:append-outer="menuBirthdate = true"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="inputCustomer.birthdate"
                      :max="moment().format('YYYY')"
                      no-title
                      @change="menuBirthdate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.yearold"
                    label="อายุ"
                    suffix="ปี"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.occupation"
                    :rules="inputRules"
                    label="อาชีพ*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.idcardnumber"
                    :rules="inputRules"
                    label="เลขประจำตัวประชาชน*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.congenital"
                    label="โรคประจำตัว"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.medicineused"
                    label="ยาที่ใช้ประจำ"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                >
                  <v-text-field
                    v-model="inputCustomer.address"
                    :rules="inputRules"
                    label="ที่อยู่*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.phonenumber"
                    :rules="inputRules"
                    label="เบอร์โทรศัพท์*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.reservephone"
                    label="เบอร์โทรศัพท์สำรอง"
                  ></v-text-field>
                </v-col>

                 <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.drugfoodallergy"
                    label="ประวัติแพ้ยา/อาหาร"
                  ></v-text-field>
                </v-col>

                 <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.drugantiblood"
                    label="ประวัติยาที่ต้านการแข็งตัวของเลือด (Vitamin E, Aspirin)"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-text-field
                    v-model="inputCustomer.historysurgery"
                    label="ประวัติการผ่าตัด/ฟิลเลอร์"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  lg="3"
                >
                  <v-checkbox
                    v-model="inputCustomer.pregnant"
                    label="ตั้งครรภ์/ให้นมบุตร"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="4">
          <div class="d-flex flex-column border-inform-customer pa-3">
            <div class="d-flex flex-column">
              <span>
                <v-icon small>mdi-circle</v-icon>
                รูปภาพลูกค้า
              </span>
              <image-input v-model="customerAvatar">
              <div slot="activator" class="d-flex flex-column justify-center align-center">
                <v-avatar size="170" v-ripple v-if="!customerAvatar" class="grey lighten-3 mb-3">
                  <span>อัพโหลดรูปภาพ</span>
                </v-avatar>
                <v-avatar size="170" v-ripple v-else class="mb-3">
                  <img :src="customerAvatar.imageURL" alt="Customer Avatar">
                </v-avatar>
              </div>
            </image-input>
            </div>
            <span>
              <v-icon small>mdi-circle</v-icon>
              ยอดค่ารักษาทั้งหมด
            </span>
            <span>
              <v-icon small>mdi-circle</v-icon>
              วงเงินคงเหลือ
            </span>
            <span>
              <v-icon small>mdi-circle</v-icon>
              แต้มสะสม
            </span>
            <span>
              <v-icon small>mdi-circle</v-icon>
              ยอดเงินค้างชำระ
            </span>
            <span>
              <v-icon small>mdi-circle</v-icon>
              จำนวน Visit ทั้งหมด
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import ImageInput from '../../../components/Image/ImageUpload.vue'

export default {
  data () {
    return {
      loaderSave: null,
      loaderCancel: null,
      formValid: false,
      inputCustomer: {
        birthdate: new Date().toISOString().substr(0, 10),
        yearold: 0,
        registerDate: moment().format('DD/MM/YYYY')
      },
      inputRules: [
        v => !!v || 'กรุณากรอกข้อมูล'
      ],
      selectRules: [
        v => !!v || 'กรุณาเลือกข้อมูล'
      ],
      menuBirthdate: false,
      customerAvatar: null,
      saving: false,
      saved: false,
      moreHistory: {
        needtoprove: [],
        finishlookdate: '',
        selectcreditcard: []
      },
      modalMoreHistory: false,
      itemsStatus: [{
        name: 'โสด',
        value: 'single'
      }, {
        name: 'สมรส',
        value: 'married'
      }],
      itemsGender: [{
        name: 'ชาย',
        value: 'male'
      }, {
        name: 'หญิง',
        value: 'female'
      }],
      itemsNeedProve: ['ลดริ้วรอย', 'ปรับรูปหน้า', 'แก้ไขแก้มหย่อนคล้อย', 'ปรับผิวขาว', 'กระชับสัดส่วน', 'ลดสิว', 'ลดฝ้ากระ', 'แก้ไขใต้ตาคล้ำ/ลึก', 'แก้ไขหน้าตอบ'],
      searchNeedProve: null,
      menuFinishLookdate: false,
      itemsSelectClinic: ['ราคา', 'การให้บริการของพนักงาน', 'ใกล้บ้าน', 'เคยมีคนรู้จักมาใช้บริการและแนะนำ', 'รีวิวน่าสนใจ', 'ความน่าเชื่อถือของแพทย์'],
      searchSelectClinic: null,
      itemsCreditCard: ['SCB', 'KBank', 'KTC', 'TMB', 'UOB', 'CITI', 'Thanachart'],
      searchCreditCard: null,
      // dataMock: [{
      //   _id: '0000',
      //   history_datetime: new Date(),
      //   history_lists: []
      // }],
      dataMock: [],
      loaddata: false
    }
  },
  components: {
    ImageInput: ImageInput
  },
  created () {
    const self = this
    if (self.$route.params.firstname) self.inputCustomer.fname = self.$route.params.firstname
    if (self.$route.params.lastname) self.inputCustomer.lname = self.$route.params.lastname
    if (self.$route.params.phonenumber) self.inputCustomer.phonenumber = self.$route.params.phonenumber
    self.initData()
  },
  watch: {
    customerAvatar: {
      handler () {
        this.saved = false
      },
      deep: true
    }
  },
  computed: {
    computedBirthdateFormatted () {
      const self = this
      const year = moment().diff(self.inputCustomer.birthdate, 'years')
      self.inputCustomer.yearold = year
      return self.inputCustomer.birthdate ? moment(self.inputCustomer.birthdate).format('DD/MM/YYYY') : ''
    },
    computedFinishLookdateFormatted () {
      const self = this
      return self.moreHistory.finishlookdate ? moment(self.moreHistory.finishlookdate).format('DD/MM/YYYY') : ''
    },
    headers () {
      return [
        {
          text: 'วันที่และเวลา',
          align: 'center',
          value: 'history_datetime',
          sortable: false
        }, {
          text: 'รายการหัตถการ',
          align: 'center',
          value: 'history_lists',
          sortable: false
        }
      ]
    }
  },
  methods: {
    uploadImage () {
      this.saving = true
      setTimeout(() => this.savedAvatar(), 1000)
    },
    savedAvatar () {
      this.saving = false
      this.saved = true
    },
    addMoreHistory () {
      const self = this
      self.modalMoreHistory = true
    },
    cancelDialogMoreHistory () {
      const self = this
      self.moreHistory = {
        needtoprove: [],
        finishlookdate: '',
        selectcreditcard: []
      }
      self.modalMoreHistory = false
    },
    confirmDialogMoreHistory () {
      const self = this
      self.modalMoreHistory = false
    },
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 3000)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/Customer.css';
</style>
